<template>
  <v-card
    class="settings-card"
    elevation="3"
    rounded="lg"
  >
    <v-card-title class="card-title">
      <div class="d-flex align-center">
        <v-icon color="primary" size="28" class="mr-3">mdi-account-group</v-icon>
        <span class="text-h5 font-weight-medium">Users & Staff</span>
      </div>
    </v-card-title>
    
    <template v-if="!loading">
      <v-card-text class="card-content">
        <div class="user-list">
          <UserCard
            v-for="(user, index) in company.users"
            :key="`user-${index}`"
            :user="user"
            class="mb-6"
          />
          
          <UserCard
            v-for="(invitation, index) in company.invitations"
            :key="`invitation-${index}`"
            :user="invitation"
            :is_invitation="true"
            class="mb-6"
          />
          
          <transition name="fade">
            <UserCard 
              v-if="add_user"
              :new_user="true"
              @collapse="add_user = false"
              class="mb-6 new-user-card"
            />
          </transition>
        </div>
      </v-card-text>
      
      <v-divider v-if="company.users.length > 0 || company.invitations.length > 0"></v-divider>
      
      <v-card-actions class="card-actions">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          rounded
          elevation="2"
          @click="toggle_add_user"
          :disabled="get_plan && company.users.length + company.invitations.length >= get_plan.max_staff"
          class="action-button"
        >
          <v-icon v-if="!add_user" left>mdi-account-plus</v-icon>
          <v-icon v-else left>mdi-close</v-icon>
          {{ !add_user ? 'Invite User' : 'Cancel' }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      
      <v-card-text v-if="get_plan && company.users.length + company.invitations.length >= get_plan.max_staff" class="text-center caption-text">
        <v-alert
          dense
          outlined
          type="info"
          class="plan-limit-alert"
        >
          <div class="d-flex align-center">
            <v-icon color="info" class="mr-2">mdi-information</v-icon>
            <span>You've reached the maximum number of users for your current plan. Upgrade to add more staff members.</span>
          </div>
        </v-alert>
      </v-card-text>
    </template>
    
    <template v-else>
      <v-card-text class="loading-container">
        <v-progress-circular
          indeterminate
          size="64"
          width="5"
          color="primary"
          class="mb-4"
        ></v-progress-circular>
        <div class="text-body-1 grey--text text--darken-1">Loading users...</div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserCard from '@/components/UserCard'

export default {
  components: {
    UserCard,
  },
  data(){
    return {
      loading: true,
      add_user: false,
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    })
  },
  methods: {
    toggle_add_user(){
      if(this.get_plan && this.company.users.length + this.company.invitations.length >= this.get_plan.max_staff) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more staff accounts. Upgrade to add more!`)
      }
      this.add_user = !this.add_user
    },
    async remove_user(id){
      const { ok } = await this.$prompt({
        text: 'Are you sure? This action can not be undone.',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      })
      if(!ok) return 
      this.$store.dispatch('warehouse/remove_user', id)
    },
  },
  async mounted(){
    await this.$store.dispatch('user/get_company_users')
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.settings-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 0.8s ease-out;
  margin-bottom: 24px;
  background-color: #ffffff;
  
  &:hover {
    // transform: translateY(-5px);
    // box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
  
  .card-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px 24px;
    background-color: #f9f9fa;
  }
  
  .card-content {
    padding: 24px;
  }
  
  .card-actions {
    padding: 16px 24px;
  }
  
  .action-button {
    min-width: 160px;
    height: 44px;
    font-weight: 500;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    
    &:hover {
      transform: translateY(-2px);
    }
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 32px;
    
    .v-progress-circular {
      animation: pulse 2s infinite ease-in-out;
    }
  }
  
  .user-list {
    animation: fadeIn 0.6s ease-out;
  }
  
  .new-user-card {
    animation: slideDown 0.4s ease-out;
  }
  
  .plan-limit-alert {
    margin-top: 16px;
    border-left-width: 4px !important;
  }
  
  .caption-text {
    padding-top: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
