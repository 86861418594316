<template>
  <div class="signature-input">
    <!-- Loading State -->
    <div v-if="loading" class="text-center py-2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="24"
        width="2"
      ></v-progress-circular>
      <div class="text-caption grey--text mt-1">Loading signature...</div>
    </div>
    
    <!-- Signature Upload Form -->
    <template v-else-if="!image_url">
      <v-row no-gutters align="center">
        <v-col cols="11">
          <v-file-input
            label="Upload Signature"
            :loading="loading_upload"
            accept="image/png"
            hint="Make sure signature is max 200kb, 350-600px wide, 100-200px high and has transparent background"
            persistent-hint
            @change="on_file_input"
            ref="file_input"
            :error-count="error_messages.length"
            :error-messages="error_messages"
            validate-on-blur
            prepend-icon="mdi-signature-image"
            :rules="[
              value => !value || value.size < 200000 || 'File size should be less than 200kb',
            ]"
            outlined
            rounded
            dense
            hide-details="auto"
            class="signature-file-input"
          ></v-file-input>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <ToolTip>Upload a signature to automatically print on customs declarations. Make sure the image has transparent background.</ToolTip>
        </v-col>
      </v-row>
      
      <!-- Error Messages -->
      <div v-if="error_messages.length > 0" class="error-container mt-2">
        <v-alert
          type="error"
          dense
          outlined
          class="mb-0"
        >
          <div v-for="(error, index) in error_messages" :key="`error-${index}`" class="error-message">
            <v-icon small color="error" class="mr-1">mdi-alert-circle</v-icon>
            {{ error }}
          </div>
        </v-alert>
      </div>
    </template>
    
    <!-- Signature Preview -->
    <template v-else-if="image_url">
      <v-card outlined rounded="lg" class="signature-preview pa-2">
        <div class="d-flex align-center">
          <div class="flex-grow-1 mr-2">
            <v-img
              :src="image_url"
              max-height="45px"
              contain
              class="signature-image"
            ></v-img>
          </div>
          
          <v-btn
            icon
            small
            color="error"
            @click="remove_image"
            class="remove-btn"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import ToolTip from '@/components/ToolTip'
import firebase from 'firebase/app'
import 'firebase/storage'
import { mapState } from 'vuex'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data: () => {
    return {
      loading_upload: false,
      image_url: '',
      loading: true,
      error_messages: [],
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
    }),
  },
  components: {
    ToolTip
  },
  methods: {
    async on_file_input(file) {
      this.error_messages = []
      if (!file) return
      if (!this.$refs.file_input.validate()) return
      
      const img = new Image()
      const url = window.URL.createObjectURL(file)
      const self = this
      
      img.onload = async function() {
        if (this.width < 350) self.error_messages.push(`Minimum 350px wide, image is ${this.width}px`)
        if (this.width > 600) self.error_messages.push(`Maximum 600px wide, image is ${this.width}px`)
        if (this.height < 100) self.error_messages.push(`Minimum 100px height, image is ${this.height}px`)
        if (this.height > 200) self.error_messages.push(`Maximum 200px height, image is ${this.height}px`)
        
        if (self.error_messages.length) return self.loading_upload = false
        
        try {
          await firebase.storage().ref().child(`signatures/${self.company.id}/${self.user.id}.png`).put(file)
          self.image_url = await firebase.storage().ref().child(`signatures/${self.company.id}/${self.user.id}.png`).getDownloadURL()
        } catch (error) {
          self.error_messages.push(`Upload failed: ${error.message}`)
        } finally {
          self.loading_upload = false
        }
      }
      
      this.loading_upload = true
      img.src = url
    },
    
    async remove_image() {
      try {
        await firebase.storage().ref().child(`signatures/${this.company.id}/${this.user.id}.png`).delete()
        this.image_url = ''
      } catch (error) {
        this.$store.commit('app/SET_SNACK_BAR', `Failed to remove signature: ${error.message}`)
      }
    }
  },
  
  async mounted() {
    try {
      const image = await firebase.storage().ref().child(`signatures/${this.company.id}/${this.user.id}.png`).getDownloadURL()
      this.image_url = image
    } catch (e) {
      // Signature doesn't exist, which is fine
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.signature-input {
  animation: fadeIn 0.4s ease-out;
  
  .signature-file-input {
    transition: all 0.3s ease;
    
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }
  }
  
  .signature-preview {
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.02);
    
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      
      .remove-btn {
        opacity: 1;
      }
    }
    
    .signature-image {
      background-color: white;
      border-radius: 4px;
      padding: 4px;
    }
    
    .remove-btn {
      opacity: 0.7;
      transition: all 0.2s ease;
      
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  
  .error-container {
    animation: slideIn 0.3s ease-out;
  }
  
  .error-message {
    font-size: 0.85rem;
    margin-bottom: 4px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
