<template>
  <v-form @submit.prevent="save_user" ref="user_form">
    <v-card
      class="user-card"
      :class="{ 'new-user': new_user, 'invitation': is_invitation }"
      elevation="2"
      rounded="lg"
    >
      <v-card-text class="pa-0">
        <!-- Existing User or Invitation -->
        <template v-if="!new_user">
          <div class="user-header" :class="{ 'owner-header': is_owner(user.id), 'self-header': is_self(user.id) && !is_owner(user.id) }">
            <div class="d-flex align-center px-4 py-3">
              <v-avatar color="primary" size="40" class="mr-3">
                <span class="white--text">{{ user.name ? user.name.charAt(0).toUpperCase() : user.email.charAt(0).toUpperCase() }}</span>
              </v-avatar>
              
              <div class="user-info">
                <div class="d-flex align-center">
                  <h3 class="text-subtitle-1 font-weight-medium mb-0">{{ user.name || user.email }}</h3>
                  <v-chip 
                    v-if="is_owner(user.id) || is_self(user.id)"
                    x-small 
                    class="ml-2" 
                    :color="is_owner(user.id) ? 'amber darken-2' : is_self(user.id) ? 'primary' : ''"
                    :text-color="is_owner(user.id) || is_self(user.id) ? 'white' : ''"
                  >
                    {{ is_owner(user.id) ? 'Owner' : is_self(user.id) ? 'You' : '' }}
                  </v-chip>
                  <v-chip 
                    v-if="is_invitation" 
                    x-small 
                    color="warning" 
                    class="ml-2"
                  >
                    Pending
                  </v-chip>
                </div>
                <div class="d-flex align-center mt-1">
                  <v-icon small color="grey darken-1" class="mr-1">{{ user.authenticator === 'firebase' ? 'mdi-email' : 'mdi-account' }}</v-icon>
                  <span class="text-caption grey--text text--darken-1">
                    {{ user.authenticator === 'firebase' ? user.email : user.authenticator }}
                  </span>
                </div>
              </div>
              
              <v-spacer></v-spacer>
              
              <v-btn
                icon
                small
                color="error"
                v-if="!is_owner(user.id) && !is_self(user.id)"
                :loading="removing_user"
                @click="remove_user(user.id)"
                class="remove-btn"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </div>
          
          <v-divider></v-divider>
          
          <!-- Invitation Message -->
          <div v-if="is_invitation" class="invitation-message pa-4">
            <v-alert
              type="info"
              outlined
              dense
              class="mb-0"
            >
              This user is invited but has not yet signed up. They will get access to {{ company.name }} when signing up on {{ $appUrl }} using this email address.
            </v-alert>
          </div>
          
          <!-- Signature Input -->
          <div v-if="!is_invitation && company.carriers.some(c => c.type === 'postnord' || c.type === 'dhl_parcel_de')" class="signature-section pa-4">
            <div class="text-subtitle-2 font-weight-medium mb-2">
              <v-icon small color="primary" class="mr-1">mdi-signature-freehand</v-icon>
              Signature
            </div>
            <SignatureInput :user="user" />
          </div>
          
          <!-- Permissions Section -->
          <div class="permissions-section pa-4" v-if="!is_owner(user.id)">
            <div class="text-subtitle-2 font-weight-medium mb-2">
              <v-icon small color="primary" class="mr-1">mdi-shield-account</v-icon>
              Permissions
            </div>
            <v-select
              v-model="user_scopes"
              :items="scopes"
              :disabled="is_self(user.id)"
              chips
              small-chips
              deletable-chips
              :rules="[v => v.length > 0 || 'At least one permission required']"
              item-text="text"
              item-value="id"
              label="User permissions"
              @change="save_user_scopes"
              multiple
              outlined
              rounded
              hide-details="auto"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 3" small>
                  <span>{{ item.text }}</span>
                </v-chip>
                <span
                  v-if="index === 3"
                  class="grey--text text-caption"
                >
                  (+{{ user_scopes.length - 3 }} others)
                </span>
              </template>
            </v-select>
          </div>
        </template>
        
        <!-- New User Form -->
        <template v-else>
          <div class="new-user-header px-4 py-3">
            <div class="d-flex align-center">
              <v-icon color="primary" size="28" class="mr-2">mdi-account-plus</v-icon>
              <h3 class="text-h6 font-weight-medium mb-0">Invite New User</h3>
            </div>
          </div>
          
          <v-divider></v-divider>
          
          <div class="pa-4">
            <v-text-field
              v-model="user.email"
              :rules="[
                v => v && v.length > 3 || 'Email is required',
                v => v && v.indexOf('@') !== -1 || 'Invalid email address'
              ]"
              label="Email Address"
              type="email"
              outlined
              rounded
              hide-details="auto"
              class="mb-4"
              prepend-inner-icon="mdi-email"
            />
            
            <div class="text-subtitle-2 font-weight-medium mb-2">
              <v-icon small color="primary" class="mr-1">mdi-shield-account</v-icon>
              Permissions
            </div>
            <v-select
              v-model="user_scopes"
              :items="scopes"
              chips
              small-chips
              deletable-chips
              :rules="[v => v.length > 0 || 'At least one permission required']"
              item-text="text"
              item-value="id"
              label="User permissions"
              multiple
              outlined
              rounded
              hide-details="auto"
              class="mb-4"
            />
            
            <div class="d-flex justify-center">
              <v-btn 
                color="primary"
                :loading="saving_user"
                type="submit"
                rounded
                elevation="2"
                class="px-6"
              >
                <v-icon left>mdi-email-send</v-icon>
                Send Invitation
              </v-btn>
              
              <v-btn 
                text
                @click="$emit('collapse')"
                class="ml-4"
              >
                Cancel
              </v-btn>
            </div>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import SignatureInput from '@/components/SignatureInput'

export default {
  components: {
    SignatureInput,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({
        authenticator: 'firebase',
        email: '',
      })
    },
    new_user: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_invitation: {
      type: Boolean, 
      required: false,
      default: false,
    }
  },
  data(){
    return {
      user_scopes: [],
      saving_user: false,
      removing_user: false,
      scopes: [
        {id: 'fulfill', text: 'Fulfill orders'},
        {id: 'warehouse', text: 'Edit warehouse layout'},
        {id: 'logs', text: 'Logs & Alerts'},
        {id: 'shipments', text: 'Shipments & Returns'},
        {id: 'plans', text: 'Plans and pricing'},
        {id: 'settings.company', text: 'Fulfillment & Product settings'},
        {id: 'settings.stores', text: 'Edit, add and remove Stores and Shipping maps'},
        {id: 'settings.carriers', text: 'Edit, add and remove carriers'},
        {id: 'settings.printers', text: 'Edit printers'},
        {id: 'settings.users', text: 'Edit, add and remove users'},
      ]
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      userProfile: state => state.user.userProfile,
    }),
    is_external(){
      return this.user.authenticator === 'firebase'
    }
  },
  methods: {
    save_user_scopes(){
      if(this.user_scopes.length === 0 || this.new_user) return
      this.$store.dispatch('user/set_user_scopes', { 
        id: this.user.id, 
        scopes: this.user_scopes, 
        is_invitation: this.is_invitation 
      })
    },
    async save_user(){
      if(!this.$refs.user_form.validate()) return
      this.saving_user = true
      try {
        this.user.email = this.user.email.toLowerCase().replace(/ /gi, '')
        const user = { ...this.user, scopes: this.user_scopes }
        await this.$store.dispatch('user/invite_user', user)
        this.saving_user = false
        this.$emit('collapse')
      } catch (e) {
        this.$store.commit('app/SET_SNACK_BAR', e.message)
        this.saving_user = false
      }
    },
    is_owner(id){
      return this.company.owner === id
    },
    is_self(id){
      return this.userProfile.id === id
    },
    async remove_user(id){
      const { ok } = await this.$prompt({
        text: 'Are you sure? This action can not be undone.',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      })
      if(!ok) return
      this.removing_user = true
      await this.$store.dispatch('warehouse/remove_user', { 
        id, 
        is_invitation: this.is_invitation 
      })
      this.removing_user = false
    },
  },
  mounted(){
    if(!this.new_user){
      this.user_scopes = this.company.users.concat(this.company.invitations)
        .filter(user => user.id === this.user.id)[0]
        .companies
          .filter(company => company.id === this.company.id)[0]
          .scopes
    } else {
      this.user_scopes = ['fulfill']
    }
  }
}
</script>

<style lang="scss" scoped>
.user-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
  
  &.new-user {
    border: 2px dashed var(--v-primary-base);
    background-color: rgba(var(--v-primary-base-rgb), 0.05);
  }
  
  &.invitation {
    border-left: 4px solid var(--v-warning-base);
  }
  
  .user-header {
    background-color: #f9f9fa;
    
    &.owner-header {
      background-color: rgba(255, 193, 7, 0.1);
    }
    
    &.self-header {
      background-color: rgba(var(--v-primary-base-rgb), 0.1);
    }
  }
  
  .new-user-header {
    background-color: rgba(var(--v-primary-base-rgb), 0.1);
  }
  
  .user-info {
    overflow: hidden;
  }
  
  .remove-btn {
    opacity: 0.7;
    transition: all 0.2s ease;
    
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  
  .invitation-message,
  .signature-section,
  .permissions-section {
    animation: fadeIn 0.4s ease-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
